import React from "react"
import PropTypes from "prop-types"
import GTM from "@src/services/gtm"
import ToursLayout from "@src/layouts/tours"
import { ImagePropTypes } from "@src/components/core-image"
import { PageSeoPropTypes } from "@src/components/page-helmet"
import { TourDetailTilePropTypes } from "@src/components/content-tour-detail-tile"
import { orderByField } from "@src/utils/sorting"

const PageTemplate = ({ pageContext: { data, ...pageContext } }) => {
  const {
    wpPage: { seo: pageSeo },
    allWpPost: { nodes: posts },
    tenTours: {
      tours: { nodes: tourData },
      tourRegions: { nodes: tourRegions },
    },
  } = data
  const {
    basePath,
    pageNumber,
    pageCount,
    postsPerPage: toursPerPage,
    postsTotal: totalTours,
    postIds: toursOrder,
    tourRegions: tourRegionsOrder,
  } = pageContext
  GTM.dataLayerPushPageInfo({
    template: "tours-all",
  })
  return (
    <ToursLayout
      pageSeo={{ ...pageSeo, metaRobotsNoindex: "index" }}
      tourData={tourData?.sort(orderByField("id", toursOrder))}
      toursParentUri={basePath}
      toursPageNumber={pageNumber}
      toursPageCount={pageCount}
      toursPerPage={toursPerPage}
      totalTours={totalTours}
      featuredTourRegions={tourRegions?.sort(
        orderByField("slug", tourRegionsOrder)
      )}
      posts={posts}
    />
  )
}

PageTemplate.propTypes = {
  pageContext: PropTypes.shape({
    basePath: PropTypes.string,
    pageNumber: PropTypes.number,
    pageCount: PropTypes.number,
    postsPerPage: PropTypes.number,
    postsTotal: PropTypes.number,
    postIds: PropTypes.arrayOf(PropTypes.number),
    tourRegions: PropTypes.arrayOf(PropTypes.string),
    data: PropTypes.shape({
      wpPage: PropTypes.shape({
        seo: PropTypes.shape({ ...PageSeoPropTypes }),
        images: PropTypes.shape({
          header: PropTypes.shape({ ...ImagePropTypes }),
        }),
      }),
      allWpPost: PropTypes.shape({
        nodes: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string,
            uri: PropTypes.string,
            images: PropTypes.shape({
              featureTile: PropTypes.shape({ ...ImagePropTypes }),
            }),
          })
        ),
      }),
      tenTours: PropTypes.shape({
        tours: PropTypes.shape({
          nodes: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.number,
              ...TourDetailTilePropTypes,
            })
          ),
        }),
        tourRegions: PropTypes.shape({
          nodes: PropTypes.arrayOf(
            PropTypes.shape({
              title: PropTypes.string,
              uri: PropTypes.string,
              images: PropTypes.shape({
                featureTile: PropTypes.shape({ ...ImagePropTypes }),
              }),
            })
          ),
        }),
      }),
    }),
  }),
}

export default PageTemplate
